@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  color-scheme: light;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FFFFFF !important;
}

.title-font {
  font-family: 'Righteous', Regular, serif;
}

.bg-radial-gradient-particles {
  background-image: radial-gradient(#ffffff, rgb(194, 194, 194));
}

.text-svg {
  width:100%;

}

.container {
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.max-container {
  max-width: 1200px;
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.header-bg {
  width:100%;
  float:left;
  background-size:100%;
  background-image: url("assets/images/banner-bg.png");
  background-repeat: no-repeat;
  height:auto;
  background-size: 100%;
  padding: 0px 0px 20px 0px;
}

.header-section {
  background-position: center 0%;
}

.header-section.header-small {
  background-position: center 41%;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

code {
  font-family: 'Poppins', sans-serif;
}


img {
  max-width: 100%;
  height: auto;
}

:focus {
  outline: 0;
}

.paddind_bottom_0 {
  padding-bottom: 0 !important;
}

.btn-custom {
  margin-top: 20px;
  background-color: transparent !important;
  border: 2px solid #ddd;
  padding: 12px 40px;
  font-size: 16px;
}

.lead {
  font-size: 18px;
  line-height: 30px;
  color: #767676;
  margin: 0;
  padding: 0;
}

.form-control:focus {
  border-color: #ffffff !important;
  box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .25);
}

.navbar-form input {
  border: none !important;
}

.badge {
  font-weight: 500;
}

blockquote {
  margin: 20px 0 20px;
  padding: 30px;
}

button {
  border: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.full {
  float: left;
  width: 100%;
}

.logo {
  width: 100%;
  float: left;
  text-align: center;
  padding: 20px 0px 50px 0px;
}

.logo_mobile {
  width: 100%;
  float: left;
  text-align: center;
  padding-bottom: 20px;
}

.mobile_menu {
  width: 100%;
  float: left;
  display: none;
}

.navbar-light .navbar-nav .nav-link {
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
}

.navbar-nav {
  align-items: center;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
  border-radius: 20px;
  border: 2px solid #fff;
  color: #fff;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 20px;
  padding-left: 20px;
}

.nav-item {
  position: relative;
}

.menu_main {
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  width: 100%;
  text-align: center;
  margin: 0 auto;
}

.menu_main ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: inline-flex;
}

.menu_main li {
  float: left;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 18px;
  text-transform: uppercase;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  border-radius: 40px;
}

.menu_main li a {
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 10px;
}

.menu_main li a:hover {
  color: #ffffff;
  background-color: #2b2278;
}

.mb-lg-0,
.my-lg-0 {
  margin-bottom: 0!important;
  width: 20%;
  float: left;
}

.padding_0 {
  padding: 0px;
}


/* banner section start */

.banner_section {
  width: 100%;
  padding-bottom: 305px;
}

.banner_taital {
  width: 100%;
  font-size: 80px;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Righteous', Regular;
}

.banner_text {
  width: 63%;
  margin: 0 auto;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
}

.read_bt {
  width: 300px;
  margin: 0 auto;
  text-align: center;
}

.slick-dots li.slick-active button:before {
  color:white !important;
}

.slick-dots li button:before {
  color:white !important;
}

.read_bt a {
  width: 100%;
  float: left;
  font-size: 16px;
  color: #141414;
  background-color: #ffffff;
  text-align: center;
  padding: 10px 0px;
  border-radius: 30px;
  margin-top: 50px;
  font-weight: 400;
}

.read_bt a:hover {
  background-color: #2b2278;
  color: #ffffff;
}


/* banner section end */

/* services section start */

.services_section {
  width: 100%;
  padding-bottom: 60px;
}

.services_taital {
  width: 100%;
  color: #1f1f1f;
  font-weight: bold;
}

.services_text {
  width: 100%;
  font-size: 16px;
  color: #1f1f1f;
  margin: 0px;
}

.services_section_2 {
  width: 100%;
  margin-top: 50px;
}

.btn_main {
  max-width: 348px;
  min-width: 170px;
  margin: 0 auto;
  padding:0 10px;
  text-align: center;
}

.event-button:hover .btn_main span {
  background-color: #331849;
}
.btn_main span {
  width: 100%;
  float: left;
  font-size: 16px;
  color: #ffffff;
  background-color: #2b2278;
  text-align: center;
  padding: 10px 0px;
  border-radius: 30px;
  font-weight: bold;
  margin-top: 30px;
  text-transform: uppercase;
}

.btn_blog_main a {
  width: 100%;
  float: left;
  font-size: 16px;
  color: #ffffff;
  background-color: #2b2278;
  text-align: center;
  padding: 10px 20px;
  border-radius: 30px;
  font-weight: bold;
  margin-top: 40px;
  text-transform: uppercase;
}


.btn_main a:hover {
  color: #ffffff;
  background-color: #000d10;
}

.btn_main.active a {
  color: #ffffff;
  background-color: #000d10;
}


/* services section end */

.about_section {
  width: 100%;
  float: left;
  padding-bottom: 90px;
}

.about_taital_main {
  width: 100%;
  padding-top: 70px;
}

.padding_right_0 {
  padding-right: 0px;
}

.about_taital {
  width: 100%;
  font-size: 40px;
  margin-bottom:30px;
  color: #1f1f1f;
  font-weight: bold;
}

.about_text {
  width: 100%;
  float: left;
  font-size: 16px;
  color: #1f1f1f;
  margin: 0px;
  padding-top: 40px;
}

.readmore_bt {
  width: 170px;
  margin-left: auto;
  margin-right: auto;
}

.readmore_bt a {
  width: 100%;
  float: left;
  font-size: 16px;
  color: #ffffff;
  background-color: #000d10;
  text-align: center;
  padding: 10px 0px;
  border-radius: 30px;
  font-weight: bold;
  text-transform: uppercase;
}

.readmore_bt a:hover {
  color: #ffffff;
  background-color: #2b2278;
}

.about_img {
  width: 100%;
  float: left;
}


/* footer section start */

.footer_section {
  width: 100%;
  float: left;
  background-image: url(assets/images/footer-bg.png);
  height: auto;
  padding-top: 250px;
  background-size: 100% 100%;
  padding-bottom: 30px;
  background-repeat: no-repeat;
}

.input_btn_main {
  width: 50%;
  display: flex;
  background-color: #ffffff;
  border-radius: 40px;
  padding: 8px 0px;
  margin: 0 auto;
}

.mail_text {
  width: 100%;
  float: left;
  font-size: 18px;
  color: #2b2c2c;
  background-color: #ffffff;
  height: auto;
  padding: 5px 20px;
  border-radius: 20px;
  border: 0px;
}

.subscribe_bt {
  float: right;
}

.subscribe_bt a {
  width: 170px;
  float: left;
  font-size: 18px;
  color: #2b2278;
  background-color: transparent;
  padding: 5px 0px;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
}

.subscribe_bt a:hover {
  color: #000;
  background-color: transparent;
}

.social_icon {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.social_icon ul {
  margin: 0px;
  padding: 0px;
  display: inline-block;
  text-align: center;
}

.social_icon li {
  float: left;
  padding: 30px 5px;
}

.location_main {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 40px;
}

.call_text {
  width: 100%;
  float: left;
  font-size: 16px;
  color: #ffffff;
  padding-top: 10px
}

.call_text a {
  color: #ffffff;
}

.call_text a:hover {
  color: #e27141;
}

/* footer section end */

.copyright_section {
  width: 100%;
  float: left;
  padding:15px;
  background-color: #2b2278;
  height: auto;
}

.copyright_text {
  width: 100%;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  margin-left: 0px;
}

.copyright_text a {
  color: #ffffff;
}

.copyright_text a:hover {
  color: #e27141;
}

.blog_section {
  width: 100%;
  background-image: url(assets/images/blog-bg.png);
  height: auto;
  background-repeat: no-repeat;
  background-size: 100%;
  padding-bottom: 180px;
  padding-top: 240px;
}

.blog_taital {
  width: 100%;
  float: left;
  font-size: 40px;
  font-weight: bold;
  padding-top: 30px;
  text-align: center;
}

.blog_text {
  width: 80%;
  margin: 0 auto;
  font-size: 16px;
  padding-top: 40px;
  text-align: center;
}

.play_icon_main {
  width: 100%;
  margin-top: 150px;
  float: left;
}

.play_icon {
  width: 200px;
  margin: 0 auto;
  position: relative;
  z-index: 5;
}

.play_icon::before {
  content: '';
  position: absolute;
  top: 49%;
  left: 70px;
  width: 130px;
  height: 130px;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 100%;
  z-index: -1;
  animation: fadeEffect 1.3s infinite ease;
  box-shadow: 0px 0px 10px 0px;
  right: inherit;
}

.blog-text {
  text-shadow: 1px 1px 2px black;
}

@media only screen and (min-width: 2600px) {
  .header-section {
    background-position: center 41%;
  }
}

@media only screen and (min-width: 2000px) {
  .blog_section {
    padding-top:400px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1150px) {
  .header-bg h1 { font-size:40px; margin-bottom:20px; }
  .banner_section { padding-bottom:10px; }
  .blog_section {
    padding-top:140px;
    padding-bottom:100px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1150px) {
  .header-bg h1 { font-size:40px; margin-bottom:20px; }
  .banner_section { padding-bottom:10px; }
  .blog_section {
    padding-top:140px;
    padding-bottom:100px;
  }
}

@media only screen and (min-width: 280px) and (max-width: 900px) {
  .header-bg {
    background-image: url(assets/images/mobile-bg.png);
    max-height:750px;
    background-position: center center;
    background-size: cover;
    padding: 0px;
  }
  .banner_section { padding-bottom:10px; }
  .blog_section { padding-bottom:0px; padding-top:80px; }

  .header-bg h1 { font-size:40px; margin-bottom:20px; }
}

@media only screen and (min-width: 280px) and (max-width: 500px) {
  .blog_section { padding-bottom:0px; padding-top:40px; }
}

@keyframes pulse-border {
  0% {
    border-color: #f56565;
    border-width: 0;
  }
  50% {
    border-color: #f56565;
    border-width: 2px;
  }
  100% {
    border-color: #f56565;
    border-width: 0;
  }
}

.animate-pulse-border {
  animation: pulse-border 1s infinite;
}